import React from "react"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactFeedbackPage = ({ data }) => {
  const { Title, Slug, Description } = data.strapiPages

  return (
    <Layout>
      <SEO title={Title} />
      <section
        className="resume-section p-3 p-lg-5 d-flex justify-content-top"
        id={Slug}
      >
        <div className="w-100">
          <h2 className="mb-5 h1">{Title}</h2>
          <ReactMarkdown source={Description} />
        </div>
      </section>
    </Layout>
  )
}

export default ContactFeedbackPage

export const pageQuery = graphql`
  query ContactFeedbackQuery {
    strapiPages(Slug: { eq: "contact-feedback" }) {
      Title
      Slug
      Description
    }
  }
`
